import React from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import { PathTypes } from "../../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";
import { getCurrentPath } from "../../../store/auctionModal/selectors";

const DashboardLayout = React.lazy(
  () => import("../../../components/DashboardLayout")
);
const AuctionCreationNav = React.lazy(
  () =>
    import(
      "../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav"
    )
);
const QuickStart = React.lazy(
  () =>
    import(
      "../../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/QuickStart"
    )
);

const Quickstart = ({ location }) => {
  const fromPath =
    location.state && location.state.fromPath ? location.state.fromPath : null;
  const path = useSelector((state) => getCurrentPath(state));
  const onBack = () => {
    if (fromPath === PathTypes.AUCTION_CREATION_MAIN) {
      navigate("/dashboard/auctions/new");
    } else if (path === PathTypes.AUCTIONS) {
      navigate("/dashboard/auctions/");
    } else {
      navigate("/dashboard/");
    }
  };
  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        <React.Suspense fallback={<div />}>
          <DashboardLayout
            hideFooter
            style={{ paddingTop: 0 }}
            hideNav
            title="Bidddy | Create New Auction - QuickStart"
          >
            <AuctionCreationNav
              onBack={() => onBack()}
              step={1}
              totalSteps={9}
              title="Create An Auction"
              disableDraft
              hideDots
            />
            <QuickStart location={location} />
          </DashboardLayout>
        </React.Suspense>
      </SimpleSpring>
    </AuthProvider>
  );
};

export default Quickstart;
